import Logo from './logo.png'
import { Button } from "@arco-design/web-react"

import './App.css'


function App() {
  return (
    <div className="App">
      <header className="nav">
        <img src={Logo}  className='logo'/>
        <div className='company-name'>和挺网络</div>
      </header>
      <div className="banner">
        <div className="banner-content">
          <div className="banner-item">让做生意更简单</div>
          <div className="banner-item">让运营更智能</div>
          <div className="solution">获取解决方案</div>
        </div>
      </div>
      <div className="product">
        <div className="product-module-title">产品介绍</div>
        <div className="product-content">
          <div className="product-item">
            <div className="product-title">电商订单通</div>
            <p className="product-introduction">电商订单通是一个订单管理工具，适用于淘宝、拼多多、1688等多个平台</p>
            <p className="product-introduction">主要功能为打单发货，专门为服务市场订单发货、商品管理等场景量身定制</p>
          </div>
          <div className="product-item">
            <div className="product-title">更多新品研发中</div>
            <p className="product-introduction">敬请期待</p>
          </div>
        </div>
      </div>
      <div className="footer">
        <div>联系我们 hzhtnet@163.com</div>
        <div className="mt4">
          <a className="link" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023038859号-1</a >
        </div>
      </div>
    </div>
  );
}

export default App;
